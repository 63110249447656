<template>
<v-container fluid>
  <date-range @getDates="getSales"></date-range>
  <v-alert v-if="!Object.keys(sales).length && loaded" type="info">
    <p>No data to display.</p>
  </v-alert>
  <v-card ref="mapContainer" style="height: 50vh; display:flex;flex-direction:column;align-items:center;justify-content:center;">
    <v-progress-circular
      v-if="!loaded"
      indeterminate
      color="primary"
    ></v-progress-circular>
  <l-map v-if="loaded" ref="map" style="height: 100%" :zoom="zoom" :center="center">
    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
    <l-marker-cluster :options="markerClusterOptions">
      <template  v-for='sale of sales'>
        <l-marker :key='sale.id' v-if="sale.latitude" :lat-lng="[sale.latitude, sale.longitude]">
          <l-icon
            :icon-size="[20, 20]"
            :icon-anchor="[10, 20]"
            icon-url="http://icons.iconarchive.com/icons/paomedia/small-n-flat/1024/map-marker-icon.png"
          />
          <l-popup>
          {{sale.retailer}}<br />
          {{sale.numItems}} items
          </l-popup>
        </l-marker>
      </template>
    </l-marker-cluster>
  </l-map>
  </v-card>
  </v-container>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup, LIcon } from 'vue2-leaflet'
import * as L from 'leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import ReportApi from '@/api/admin/report'
import DateRange from '@/components/DateRange'

export default {
  components: {
    DateRange,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
    LMarkerCluster: Vue2LeafletMarkerCluster
  },
  data () {
    return {
      mapOverlay: true,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 5,
      center: [55.94736446069031, -3.1921626077463103],
      markerLatLng: [51.504, -0.159],
      imageUrl: process.env.VUE_APP_IMG_URL,
      sales: [],
      loaded: false,
      iconSize: 40,
      markerClusterOptions: {
        disableClusteringAtZoom: 1,
        maxClusterRadius: 160,
        iconCreateFunction: (cluster) => {
          return L.divIcon({ iconSize: [40, 40], iconAnchor: [20, 20], className: '', html: '<div class="cluster-marker">' + cluster.getChildCount() + '</div>' })
        }
      },
      headers: [
        {
          text: '',
          width: '5%',
          value: 'index'
        },
        {
          text: 'Image',
          sortable: false,
          value: 'image'
        },
        {
          text: 'Total',
          width: '8%',
          align: 'end',
          value: 'numItems'
        },
        {
          text: 'Title',
          width: '60%',
          value: 'title'
        },
        {
          text: 'Artist',
          value: 'artist'
        },
        {
          text: 'SKU',
          value: 'sku'
        }
      ]
    }
  },
  methods: {
    getMapTop () {
      return this.$refs.mapContainer && this.$refs.mapContainer.$el.getBoundingClientRect().top
    },
    onResize () {
      this.$refs.map[0].mapObject._onResize()
    },
    async getSales (dates) {
      this.loaded = false
      ReportApi.loadProductGeoSales({ dateRange: dates }).then((results) => {
        this.sales = results.data
        this.loaded = true
      })
    }
  },
  async created () {
  },
  computed: {
    dynamicSize () {
      return [this.iconSize, this.iconSize * 1.15]
    },
    dynamicAnchor () {
      return [this.iconSize / 2, this.iconSize * 1.15]
    }
  }
}
</script>
<style lang="scss">
  .cluster-marker {
    border: 1px solid red;
    background-color: yellow;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
